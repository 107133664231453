<template>
  <div class="page-not-found">
    <div class="page-not-found__container">
      <img class="page-not-found__image" src="../assets/img/404.png" alt="404">
      <span class="page-not-found__caption">
        Приехали
      </span>
      <span class="page-not-found__description">
        Похоже, эта страница не работает
      </span>
      <app-auth-button class="page-not-found__button" button-style="button_action">
        <router-link class="page-not-found__return-link" :to="{ name: 'Main' }">
          Вернуться
        </router-link>
      </app-auth-button>
    </div>
  </div>
</template>

<script>
import AppAuthButton from '@/components/AppButton'

export default {
  name: '404',
  components: {
    AppAuthButton
  }
}
</script>

<style scoped>
.page-not-found {
  padding-top: 50px;
}

.page-not-found__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-not-found__image {
  width: 140px;
  margin-bottom: 15px;
}

.page-not-found__caption {
  margin-bottom: 10px;
  font-family: "Inter Bold", "sans-serif";
  font-weight: 700;
  font-size: 24px;
}

.page-not-found__description {
  margin-bottom: 25px;
  font-size: 12px;
}

.page-not-found__button {
  width: 140px;
  font-size: 12px;
}

.page-not-found__return-link {
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 50px;
}

@media screen and (min-width: 576px) {
  .page-not-found {
    padding-top: 100px;
  }

  .page-not-found__image {
    width: 180px;
  }

  .page-not-found__caption {
    font-size: 32px;
  }

  .page-not-found__description {
    margin-bottom: 35px;
    font-size: 18px;
  }

  .page-not-found__button {
    width: 180px;
  }

  .page-not-found__return-link {
    line-height: 55px;
  }
}

@media screen and (min-width: 768px) {
  .page-not-found__return-link {
    line-height: 68px;
  }
}
</style>